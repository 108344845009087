import { find } from 'lodash'
import { createIndicatorOfStudy } from '~/modules/SDK/indicator/createIndicatorOfStudy'
import { store } from '~/pages/heineken_template/_private/store'

export const VPVR = createIndicatorOfStudy(() => {
  const allStudies = store.charting.widget?.activeChart().getAllStudies()

  const found = find(allStudies, study => study.name === 'Volume Profile Visible Range')

  if (!found) {
    store.charting.widget
      ?.activeChart()
      .createStudy('Volume Profile Visible Range', false, false, [
        'Number Of Rows',
        24,
        'Up/Down',
        0,
        0,
        100,
      ])
  }
})
