import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { stockRatioStore } from './StockRatioGraph'
import { stockScoreSensitivityStore } from './StockScoreRankSlider'
import { stockScoreSortStore } from './StockScoreSort'
import { StockScoreTable } from './StockScoreTable'
import { useStkTradeInfoResource } from './useStkTradeInfoResource'
import { useStkWeightResource } from './useStkWeightResource'

export const StockWeightListTable = memo<ReactProps<{ changeSymbol?: boolean }>>(
  function StockWeightListTable(props) {
    const sortState = useSnapshot(stockScoreSortStore)
    const sensitivityState = useSnapshot(stockScoreSensitivityStore)

    /** 股期商品近遠月分數api */
    const stkTradeInfoData = useStkTradeInfoResource()
    /** 組成新的陣列格式 */
    const stkTradeInfoArray = Object.entries(stkTradeInfoData.data).map(([key, value]) => ({
      name: key + '-1',
      near_month: value.near_month,
      next_month: value.next_month,
      total: Number(value.near_month) + Number(value.next_month),
    }))

    /** 先拿股期權重前30名 */
    const stkWeightData = useStkWeightResource()

    /** 組成新的陣列格式 */
    const stkWeightArray = Object.entries(stkWeightData.data).map(([key, value]) => ({
      name: key + '-1',
      weight: value.percentage,
      symbol: value.symbol,
    }))

    /** 取名稱 */
    const weightSymbol = stkWeightArray.map(s => s.name)

    /** (權值前30名與近遠月商品match) */
    const resultData = stkTradeInfoArray.filter(item => weightSymbol?.includes(item.name))

    /** 將順序調整成權重順序 */
    const data = resultData.sort(
      (a, b) => weightSymbol.indexOf(a.name) - weightSymbol.indexOf(b.name),
    )

    /** 近遠月之和正值家數 */
    const upCount = resultData.filter(s => s.total > 0)
    /** 近遠月之和負值家數 */
    const downCount = resultData.filter(s => s.total < 0)

    //排序 default desc asc
    if (sortState.sortType === 'default') {
      data
    }
    if (sortState.sortType === 'desc') {
      data.sort((a, b) => b.total - a.total)
    }
    if (sortState.sortType === 'asc') {
      data.sort((a, b) => a.total - b.total)
    }

    stockRatioStore.up = upCount.length
    stockRatioStore.down = downCount.length
    stockRatioStore.total = resultData.length

    return (
      <StockScoreTable
        data={data}
        rank={sensitivityState.value === 500 ? 750 : sensitivityState.value}
        changeSymbol={props.changeSymbol}
      />
    )
  },
)
