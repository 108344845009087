import useSWR from 'swr-0-5-6'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'

export type StkTradInfo = {
  [key: string]: {
    near_month: number
    next_month: number
    total: number
  }
}

const fetcher = async (url: string) => {
  const res = await fetch(url)
  if (res.status >= 400) throw new Error()
  return res.json()
}

/** 股期商品近遠月分數 */
export const useStkTradeInfoResource = (date?: string) => {
  const url = urlcat(apirc.unclassified.stkfutTradeinfoURL.toString(), { date })

  const res = useSWR<StkTradInfo>(url, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 15000,
  })

  return {
    data: res.data ? res.data : [],
    isLoading: !res.data && !res.error,
  }
}
