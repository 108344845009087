import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { goodwayStore } from '~/pages/daddy960_goodway/component/goodwayStore'

export const goodway_ma960 = createIndicator({
  id: 'goodway-ma960',
  displayName: 'goodway-ma960',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const resolution = this._context.symbol.period
      const isTX = this._context.symbol.ticker.match(/TX([A,P]M)?-\d+/)
      const isValidResolution =
        resolution === '1' ||
        resolution === '2' ||
        resolution === '3' ||
        resolution === '5' ||
        resolution === '15' ||
        resolution === '30' ||
        resolution === '60'
      if (!isTX || !isValidResolution) return

      const length = Math.ceil(960 / parseInt(resolution))
      const ma: number = this.PineJS.Std.sma(
        this._context.new_var(this.PineJS.Std.close(this._context)),
        length,
        this._context,
      )
      goodwayStore.ma960 = ma
      goodwayStore.txf = this.PineJS.Std.close(this._context)
      return [ma, ma + 200, ma + 80, ma - 110, ma - 200]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'movAvg', type: 'line' },
      { id: 'movAvgP200', type: 'line' },
      { id: 'movAvgP80', type: 'line' },
      { id: 'movAvgM110', type: 'line' },
      { id: 'movAvgM200', type: 'line' },
    ],
    defaults: {
      styles: {
        movAvg: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#33ffff',
        },

        movAvgP200: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#FF0000',
        },
        movAvgP80: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ffff66',
        },
        movAvgM110: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#a64dff',
        },
        movAvgM200: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00cc66',
        },
      },
      precision: 2,
      inputs: {},
    },
    styles: {
      movAvg: {
        title: '960',
        histogramBase: 0,
        joinPoints: false,
      },
      movAvgP200: {
        title: '960+200',
        histogramBase: 0,
        joinPoints: false,
      },
      movAvgP80: {
        title: '960+80',
        histogramBase: 0,
        joinPoints: false,
      },
      movAvgM110: {
        title: '960-110',
        histogramBase: 0,
        joinPoints: false,
      },
      movAvgM200: {
        title: '960-200',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [],
  },
})
