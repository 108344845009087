import { memo } from 'react'
import { proxy } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { StockRatioGraph } from './component/StockRatioGraph'
import { StockScoreRankSlider } from './component/StockScoreRankSlider'
import { StockScoreSort } from './component/StockScoreSort'
import { StockWeightListTable } from './component/StockWeightListTable'
import styled from '@emotion/styled'
import { StockScoreTableHeader } from './component/StockScoreTable'
import { useDaddy960Trial } from '../daddy960_opkevin/component/useDaddy960Tutorial'

export const Daddy960_goodway_SidePane1 = memo<ReactProps>(function Daddy960_goodway_SidePane1() {
  useDaddy960Trial('goodway')
  return (
    <styleds.container>
      <styleds.dashboardHeader>
        <div>氣氛靈敏度</div>
        <div>分數排序</div>
      </styleds.dashboardHeader>
      <styleds.dashboard>
        <StockScoreRankSlider />
        <StockScoreSort />
      </styleds.dashboard>
      <styleds.content height={'32px'}>
        <StockRatioGraph />
      </styleds.content>
      <styleds.content height={'32px'}>
        <StockScoreTableHeader />
      </styleds.content>
      <styleds.content height={'calc(100% - 156px)'}>
        <StockWeightListTable changeSymbol={false} />
      </styleds.content>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    font-size: 14px;
    user-select: none;
  `,
  content: styled.div<{ height: string }>`
    width: 100%;
    height: ${props => props.height};
  `,
  dashboardHeader: styled.div`
    ${fill_horizontal_all_center};
    grid-template-columns: 70% 30%;
    height: 32px;
    display: grid;
    gap: 4px;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    & > div {
      ${fill_horizontal_all_center};
      background-color: #252525;
      border-radius: 5px;
    }
  `,
  dashboard: styled.div`
    ${fill_horizontal_all_center};
    height: 40px;
    display: grid;
    gap: 4px;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    & > div {
      background-color: #252525;
      border-radius: 5px;
    }
  `,
}
