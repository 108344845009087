import useSWR from 'swr-0-5-6'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'

export type Tradeinfo = {
  tx_bvav: number
  mtx_bvav: number
  mtx_tbta: number
}

const fetcher = async (url: string) => {
  const res = await fetch(url)
  if (res.status >= 400) throw new Error()
  return res.json()
}

/** 股期商品近遠月分數 */
export const useDaddy960Tradeinfo = (date?: string) => {
  const url = urlcat(apirc.unclassified.daddy960TradeinfoURL.toString(), { date })

  const res = useSWR<Tradeinfo>(url, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 15000,
  })

  return {
    data: res.data ? res.data : { tx_bvav: 0, mtx_bvav: 0, mtx_tbta: 0 },
    isLoading: !res.data && !res.error,
  }
}
