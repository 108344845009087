import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { goodway_ma960 } from '~/trades/indicators/daddy960_goodway/goodway_ma960'
import { VPVR } from '~/trades/indicators/defaults/VPVR'
import { goodway_txChip } from '~/trades/indicators/daddy960_goodway/goodway_txChip'
import { store } from '../heineken_template/_private/store'

const DEFAULT_SYMBOL = 'TX-1'

const txIndicatorGroup = [VPVR, goodway_ma960, goodway_txChip]

export const daddy960_goodway_strategyGroup = {
  main: [
    {
      displayName: 'Main strategy group',
      indicators: txIndicatorGroup,
      symbol: DEFAULT_SYMBOL,
      interval: '1',
      panesRatio: 80,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const daddy960_goodway_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...txIndicatorGroup]

  store.charting.initStrategy({
    configs: [...daddy960_goodway_strategyGroup.main],
  })

  store.charting.changeSymbol(DEFAULT_SYMBOL)
}
