import { TemplateProps } from '../heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import { css } from '@emotion/react'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { Daddy960_goodway_SidePane1 } from './daddy960_goodway_SidePane1'
import { daddy960_goodway_initStyling } from './daddy960_goodway_initStyling'
import { daddy960_goodway_initStrategies } from './dayy960_goodway_initStrategies'
import { Daddy960_Footer } from '../daddy960_opkevin/component/daddy960_Footer'
import { Daddy960_goodway_SidePane2 } from './daddy960_goodway_SidePane2'
import { fr_serverTime } from '../heineken_template/_fr/fr_serverTime'
import { fr_me } from '../heineken_template/_fr/fr_me'
import { AgentProduct } from '~/configs/AgentProduct'
import dayAPI from '~/utils/dayAPI'
import { Daddy960_TopBar } from '../daddy960_opkevin/component/daddy960_TopBar'
import { Daddy960LoginView } from '../daddy960_opkevin/component/Daddy960LoginView'
import { Charting } from '~/pages/heineken_template/_private/Charting'
import { StockMonitors } from './component/StockMonitors'
import { OsFuturesMonitors } from './component/OsFuturesMonitors'
import { goodwayStore } from './component/goodwayStore'

export const daddy960_goodway_init = {
  global(templateProps: TemplateProps) {
    daddy960_goodway_initStyling(templateProps)

    useThemeStore.setState({ theme: 'dark' })
    store.charting.setThemeMode('dark')

    templateProps.layout.Charting = undefined

    templateProps.layout.Drawer1 = Daddy960_goodway_SidePane2

    templateProps.layout.Row1 = (
      <Daddy960_TopBar
        leftBurger={true}
        product={'goodway'}
      />
    )
  },

  indexPage(templateProps: TemplateProps) {
    daddy960_goodway_init.global(templateProps)
    daddy960_goodway_initStrategies()
    goodwayStore.pageState = 'index'

    useThemeStore.setState({ theme: 'dark' })
    store.charting.setThemeMode('dark')

    // templateProps.permissions.pageview =
    //   meCheckHandlerCreateByProductNameWithExpiredAt('web_goodway')
    templateProps.hooks.add(fr_serverTime.useInstall)
    templateProps.hooks.add(fr_me.useInstall)

    templateProps.permissions.pageview =
      fr_me.toTemplate.permissions.hasPermissionOrHasLoginBeforeServerDate({
        agentProduct: AgentProduct['daddy960@web_goodway'],
        date: dayAPI('2023/3/25 06:00'),
      })

    store.charting.widgetOptions = {
      ...store.charting.widgetOptions,
      symbol: 'TX-1',
      interval: '1',
      enableVolumeIndicator: false,
      disabledHeaderWidget: false,
      disabledLeftToolbar: false,
      disabledPaneMenu: true,
      disabledTimeframesToolbar: false,
      disabledHeaderChartType: false,
      disabledHeaderSaveload: false,
      disabledHeaderCompare: false,
      overrides: {
        ...store.charting.darkOverrides,
      },
    }

    templateProps.layout.cssset = css`
      grid-template-rows: 48px calc(100vh - 48px) 1fr;
      grid-template-columns: 336px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Chart'
        'Row2 Row2 Row2';

      ${createIPadCss(css`
        grid-template-rows: 48px calc(100% - 56px) 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px calc(100% - 56px) 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}
    `

    // Init components
    templateProps.layout.Charting = Charting
    templateProps.layout.Row1 = (
      <Daddy960_TopBar
        leftBurger={true}
        product={'goodway'}
      />
    )
    templateProps.layout.Row2 = Daddy960_Footer
    templateProps.layout.Drawer1 = Daddy960_goodway_SidePane2
    templateProps.layout.Drawer2 = Daddy960_goodway_SidePane1
    templateProps.layout.login = (
      <Daddy960LoginView
        product='goodway'
        faviconUrl='goodway/favicon.png'
        lineLink='https://page.line.me/?accountId=730hvfcs'
      />
    )
  },
  ['goodway/monitors/index.page'](templateProps: TemplateProps) {
    daddy960_goodway_init.global(templateProps)
    goodwayStore.pageState = 'monitors'

    templateProps.hooks.add(fr_serverTime.useInstall)
    templateProps.hooks.add(fr_me.useInstall)

    templateProps.permissions.pageview =
      fr_me.toTemplate.permissions.hasPermissionOrHasLoginBeforeServerDate({
        agentProduct: AgentProduct['daddy960@web_goodway'],
        date: dayAPI('2023/3/25 06:00'),
      })

    templateProps.layout.Row1 = (
      <Daddy960_TopBar
        leftBurger={true}
        rightBerger={false}
        product={'goodway'}
      />
    )
    templateProps.layout.Row2 = Daddy960_Footer

    templateProps.layout.Col2 = StockMonitors
    templateProps.layout.login = (
      <Daddy960LoginView
        product='goodway'
        faviconUrl='goodway/favicon.png'
        lineLink='https://page.line.me/?accountId=730hvfcs'
      />
    )

    templateProps.layout.cssset = css`
      grid-template-rows: 48px calc(100vh - 48px) 1fr;
      grid-template-columns: 336px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Col2 Col2'
        'Row2 Row2 Row2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Row2 Row2 Row2';
      `)}
    `
  },
  ['goodway/os-monitors/index.page'](templateProps: TemplateProps) {
    daddy960_goodway_init.global(templateProps)
    goodwayStore.pageState = 'os-monitors'

    templateProps.hooks.add(fr_serverTime.useInstall)
    templateProps.hooks.add(fr_me.useInstall)

    templateProps.permissions.pageview =
      fr_me.toTemplate.permissions.hasPermissionOrHasLoginBeforeServerDate({
        agentProduct: AgentProduct['daddy960@web_goodway'],
        date: dayAPI('2023/3/25 06:00'),
      })

    templateProps.layout.Row1 = (
      <Daddy960_TopBar
        leftBurger={true}
        rightBerger={false}
        product={'goodway'}
      />
    )
    templateProps.layout.Row2 = Daddy960_Footer

    templateProps.layout.Col2 = OsFuturesMonitors
    templateProps.layout.login = (
      <Daddy960LoginView
        product='goodway'
        faviconUrl='goodway/favicon.png'
        lineLink='https://page.line.me/?accountId=730hvfcs'
      />
    )

    templateProps.layout.cssset = css`
      grid-template-rows: 48px calc(100vh - 48px) 1fr;
      grid-template-columns: 336px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Col2 Col2'
        'Row2 Row2 Row2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Row2 Row2 Row2';
      `)}
    `
  },
}
